<!--
 * @Author: your name
 * @Date: 2021-10-12 15:43:57
 * @LastEditTime: 2021-10-25 11:23:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vaton-web-system\src\components\header.vue
-->
<template>
  <div class="header">
    <div class="header_boxContent">
      <div>
        <div class="title">
          <i :class="changeIcon" style="font-size: 38px;cursor: pointer" @click="changeMenu"></i>
          <img src="@/assets/logo_icon.png" class="logo_img" />
          <span class="title-info">卫健公社健康管理系统</span>
        </div>
      </div>
      <div class="right-infos">
        <div class="time-week">
          <p class="time">{{ nowTime }}</p>
          <p>{{ nowDate }}{{ nowWeek }}</p>
        </div>
        <img src="@/assets/images/line_img.png" class="split_line" />
        <div class="admin"><span>{{ username }}</span></div>
        <img src="@/assets/images/line_img.png" class="split_line" />
        <img src="@/assets/images/out_icon.png" class="out_box" @click="goOut" />
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/index";
import { chinaArea } from "@/utils/china-area-data";
export default {
  name: "header-box",
  data() {
    return {
      username: "",
      nowTime: "",
      nowWeek: "",
      nowDate: "",
      changeIcon: 'el-icon-s-fold'
    };
  },
  created() {
    this.nowTimes();
    this.getUser();
    this.getAreaList()
  },
  mounted() {

  },
  methods: {

    //根据当前用户获取区域列表
    //根据当前用户获取区域列表
    async getAreaList() {
      try {
        let res = await api.getUserArea()
        console.log(res)
        if (res.code == 200 && res.rows) {
          this.$store.commit('saveUsername', res.rows);
        }
      } catch (e) {

      }
    },
    goOut() {
      this.$notify.closeAll();
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
    //显示当前时间（年月日时分秒、星期）
    timeFormate(timeStamp) {
      let year = new Date(timeStamp).getFullYear();
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? "0" + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1;
      let date =
        new Date(timeStamp).getDate() < 10
          ? "0" + new Date(timeStamp).getDate()
          : new Date(timeStamp).getDate();
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      let ss =
        new Date(timeStamp).getSeconds() < 10
          ? "0" + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds();
      this.nowTime = hh + ":" + mm + ":" + ss;
      this.nowWeek = "星期" + "日一二三四五六".charAt(new Date().getDay());
      this.nowDate = year + "-" + month + "-" + date;
    },
    nowTimes() {
      this.timeFormate(new Date());
      setInterval(this.nowTimes, 1000);
      this.clear();
    },
    clear() {
      clearInterval(this.nowTimes);
      this.nowTimes = null;
    },
    async getUser() {
      try {
        let res = await api.getUserInfo();
        if (res.code == 200) {
          this.$store.commit('saveUserInfo', { userId: res.user.userId, role: res.roles[0] });
          this.username = res.user.userName;
        } else {
          this.username = "";
          this.$message({
            message: res.msg,
            type: error,
          });
        }
      } catch (error) {
        this.username = "";
        this.$message.error(error);
      }
    },
    changeMenu() {
      if (this.changeIcon == 'el-icon-s-fold') {
        this.changeIcon = 'el-icon-s-unfold'
      } else {
        this.changeIcon = 'el-icon-s-fold'
      }
      this.$emit('changeCollapse')
    }
  },
  watch: {

  },
};
</script>

<style scoped  lang="less">
.header {
  .header_boxContent {
    height: 70px;
    line-height: 70px;
    background: #02866a;
    padding: 0 20px 0 5px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .title {
      color: #fff;
      align-items: center;
      display: flex;

      .logo_img {
        width: 45px;
        height: 45px;
        margin-right: 5px;
        margin-left: 10px;
      }

      .title-info {
        font-family: YouSheBiaoTiHei;
        font-size: 40px;
        background-image: -webkit-linear-gradient(bottom, #f3ffff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .right-infos {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .time-week {
        margin-right: 10px;
        padding: 9px 0;
        background-image: -webkit-linear-gradient(bottom, #f3ffff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        p {
          line-height: 20px;
          font-size: 14px;
          font-weight: bold;
          color: #fff;
          text-align: right;
        }

        .time {
          font-size: 18px;
        }
      }

      .split_line {
        margin: 0 10px;
      }

      .out_box {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        cursor: pointer;
      }

      .admin {
        margin-right: 10px;
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        font-family: YouSheBiaoTiHei;

        span {
          background-image: -webkit-linear-gradient(bottom, #f3ffff, #ffffff);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .login-noAlarm {
        img {
          width: 32px;
          height: 32px;
          vertical-align: -4px;
        }
      }

      .login-alarm {
        animation: fade 500ms infinite;
        -webkit-animation: fade 500ms infinite;

        img {
          width: 32px;
          height: 32px;
          vertical-align: -4px;
        }
      }
    }
  }
}
</style>

