<template>
  <div class="content">
    <v-head @changeCollapse="changeCollapse"></v-head>
    <div class="home_content">
      <v-menu :isCollapse="isCollapse"></v-menu>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import vHead from "./header.vue";
import vMenu from "./menu.vue";
export default {
  name: "home",
  data() {
    return {
      isCollapse: false,
    };
  },
  components: {
    vHead,
    vMenu,
  },
  methods: {
    changeCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
};
</script>
<style scoped lang="less">
.content {
  height: 100%;
  width: 100%;
  .home_content {
    height: calc(100% - 71px);
    margin-top: 1px;
    display: flex;
  }
}
</style>
