<template>
  <div class="mene_box">
    <el-menu class="el-menu-vertical-demo"
             :default-active="activ"
             active-text-color="#fff"
             @select="handleSelect"
             :unique-opened="true"
             :collapse="isCollapse"
    >
      <template v-for="item in menus">
        <el-submenu :index="item.path" :key="item.menuId" v-if="item.child && item.child!=''">
          <template slot="title">
            <i class="iconfont" :class="item.icon"></i>
            <span slot="title">{{ item.menuName }}</span>
          </template>
          <el-menu-item-group v-for="citem in item.child" :key="citem.menuId">
            <el-menu-item :index="citem.path">
              <i  class="iconfont" :class="citem.icon"></i>{{ citem.menuName}}
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item :index="item.path" v-else :key="item.menuId">
          <i class="iconfont" :class="item.icon"></i>
          <span slot="title">{{ item.menuName }}</span>
        </el-menu-item>
      </template>

    </el-menu>
  </div>
</template>
<script>
  import api from '@/api/index'
export default {
  props: {
    isCollapse: {
      type: Boolean,
      required: true
    }
  },
  name: 'mainindex',
  data () {
    return {
      menus: [],
      activ: 'home',
    }
  },
  created () {
    this.getMenuList()
  },
  mounted () {
    this.activ = this.$route.name

  },
  methods: {

    async getMenuList(){
      let roleId =  localStorage.getItem('roldId')

      try {
        let res= await api.getMenu({roleId:roleId})
        if(res.code==200 && res.data){
          this.menus=res.data
          console.log(this.menus)
        }else {
          this.menus=[{
            id: 1, menuName: '首页', path: 'home', icon: 'icon-homefill'
          }]
        }
      }catch (e) {
        this.menus=[{
          id: 1, menuName: '首页', path: 'home', icon: 'icon-homefill'
        }]
      }
    },
    handleSelect (key) {
      console.log(key)
      this.$router.push({ name: key })
      this.activ =key
    }

  },
  watch: {

  }
}
</script>

<style scoped lang="less">
  .content_box {
    width: 100%;
    height: 100%;
  }

  .mene_box {
    height: 100%;
    overflow: auto;
    background-color: #0b9580;
  }

  /deep/ .el-menu-item:hover, /deep/ .el-menu-item.is-active {

    background-image: radial-gradient(rgb(22, 219, 138), rgb(42, 150, 106)) !important;
    background-position-x: initial !important;
    background-position-y: initial !important;
    background-size: initial !important;
    background-repeat-x: initial !important;
    background-repeat-y: initial !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: rgb(42, 150, 106) !important;
    -webkit-transition: background-color .5s;
    transition: background-color .5s;

  }

  /deep/ .el-submenu {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  /deep/ .el-menu-item-group__title {
    padding: 0 !important;
  }

  .el-menu-item {
    color: #fff;
  }

  /deep/.el-submenu__title {
    color: #fff !important;
  }
  /deep/.el-menu{
    .el-menu-item-group{
      .el-menu-item {
        color: #fff;
      }
    }
  }

  /deep/ .el-submenu__title:hover ,.el-menu-item.is-active{
    background-image: radial-gradient(rgb(22, 219, 138), rgb(42, 150, 106)) !important;
    background-position-x: initial !important;
    background-position-y: initial !important;
    background-size: initial !important;
    background-repeat-x: initial !important;
    background-repeat-y: initial !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: rgb(42, 150, 106) !important;
    -webkit-transition: background-color .5s;
    transition: background-color .5s;
  }

  /deep/ .iconfont {
    margin-right: 5px;
    color: #fff;
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 180px;
  }
</style>
